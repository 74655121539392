import React from "react";
import Hero from "../components/playbook/hero";
import LocalizedLink from "../components/localizedLink";
import { graphql } from "gatsby";
import useTranslations from "../components/useTranslations";
import { Helmet } from "react-helmet";
import Newsletter from "../components/playbook/newsletter";
import HelmetHeader from "../components/helmetHeader";

const Playbook = ({ data: { allMdx }, location }) => {
  const { playbook, lang } = useTranslations();
  return (
    <>
      <HelmetHeader
        location={location}
        lang={lang}
        title={playbook.metas.title}
        metas={[{ name: "description", content: playbook.metas.description }]}
      />
      <Hero />
      <div className="w-full bg-gray-50">
        <div className="relative max-w-4xl mx-auto">
          <div className="pt-5 flex w-100 justify-center">
            <span className="font-medium text-sm">
              {playbook.content.table}
            </span>
          </div>
          <div className="mt-5 pb-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 px-4 lg:px-0 lg:max-w-none">
            {allMdx.edges.map(({ node: post }) => {
              return (
                <div
                  key={post.frontmatter.title}
                  className="flex bg-white flex-col rounded-lg shadow-md transition-all hover:-mt-1 hover:mb-1 hover:shadow-lg"
                >
                  <LocalizedLink
                    to={`/${post.parent.relativeDirectory}/`}
                    className="block mt-2"
                  >
                    <div className="flex-1 px-6 py-3 flex flex-col justify-between">
                      <div className="h-40">
                        <span className="text-sm font-medium text-cyan-600">
                          {post.frontmatter.chapter}
                        </span>
                        <h3 className="mt-1 text-xl font-semibold text-gray-900">
                          {post.frontmatter.title}
                        </h3>
                        <p className="mt-3 text-base text-gray-500">
                          {post.frontmatter.subtitle}
                        </p>
                      </div>
                      <div>
                        <div className="flex w-100 py-5 justify-center">
                          <img
                            className="h-40 w-auto"
                            src={post.frontmatter.imgThumbnail}
                            alt={post.frontmatter.title}
                          />
                        </div>
                      </div>
                    </div>
                  </LocalizedLink>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Newsletter />
    </>
  );
};

export default Playbook;

export const query = graphql`
  query PlaybookContent($locale: String!) {
    allMdx(
      filter: {
        fields: { locale: { eq: $locale } }
        slug: { regex: "/^playbook/" }
      }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            chapter
            title
            subtitle
            imgThumbnail
          }
          fields {
            locale
          }
          parent {
            ... on File {
              relativeDirectory
              sourceInstanceName
            }
          }
        }
      }
    }
  }
`;
